<template>
  <svg
      height="40"
      width="40"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 36.8 36"
      style="enable-background: new 0 0 36.8 36;"
      xml:space="preserve"
  >
    <path style="fill: #007799;" d="M36,13.5C34.5,4.6,28.4,0,18.3,0C6.8,0,0.4,6.2,0.4,18s6.4,18,17.9,18c10,0,16.1-4.6,17.6-13.5C36.3,20.8,36.7,16.7,36,13.5L36,13.5z M25.7,21.4c0,0-0.7,2.2-3,3.9c-2.3,1.7-4.2,2.9-4.2,2.9s-3-1.8-4.7-3.3c-1.8-1.4-2.4-3.5-2.4-3.5L11.1,11c0,0,1.9,0.1,3.9-0.5c2.2-0.6,3.4-1.4,3.4-1.4s1.7,1.1,3.5,1.4c1.9,0.3,3.7,0.5,3.7,0.5V21.4L25.7,21.4z" />
    <path style="fill: none;" d="M28,13l-0.1,10.6c0,0-0.8,2.3-3.3,4s-4.5,2.9-4.5,2.9s-3.3-1.9-5.1-3.3c-1.9-1.5-2.7-3.6-2.7-3.6L12.2,13c0,0,2,0.1,4.3-0.5s3.7-1.4,3.7-1.4s1.8,1.1,3.8,1.4C26,12.8,28,13,28,13L28,13z" />
  </svg>
</template>

<script>
export default {
  name: 'ProjectLogo'
}
</script>
